import services from "@/data/services.js"
import about from "@/data/about.js"
// import gallery from "@/data/gallery.js"
import product from "@/data/product.js"
import home from "@/data/home.js"
import text from '@/data/text.js'
const footer = {
    links: [home.name,  about.name, services.name, product.name],
    // links: [home.name,  about.name, services.name, gallery.name, product.name],
    contact: {
        telp: "+62-267 8637-5045 | 0877-8093-8428",
        mail: "admin@suryalanggeng.com",
        location: 'Jl. Intercharge Tol Karawang Barat No. 68 Karawang - Jawa Barat 41361'
    },
    hours: {
        days: "Monday - Friday",
        time: "8 am - 5 pm"
    },
    pt: text.pt,
    product: [
        {
            title: 'Cleaner & Striper',
            link: '/product-application',
            linkId: 'cleaner-stripper'
        },
        {
            title: 'Hardchrome',
            link: '/product-application',
            linkId: 'hardchrome'
        },
        {
            title: 'Decorative',
            link: '/product-application',
            linkId: 'decorative-system'
        },
        {
            title: 'Anti Corrosion',
            link: '/product-application',
            linkId: 'anti-corrosion'
        },
        {
            title: 'Etc',
            link: '/product-application',
            linkId: 'list-product-application'
        },
    ]
}

export default footer