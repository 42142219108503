<template>
  <section class="container mx-auto px-5">
      <div class="flex flex-col items-center text-center mb-10">
        <h3 class="uppercase text-xl md:text-2xl font-medium tracking-wider">{{data.name}}</h3>
        <div class="bg-cyan h-1 w-20 mt-2 mb-5"></div>
        <h2 class="text-xl md:text-2xl font-semibold mb-10 lg:mb-6">{{data.title}}</h2>
      </div>
      <div class="w-10/12 mx-auto">
        <vue-agile :options="options" class="vue-home-treat">
            <div v-for="(value, idx) in data.list" :key="idx" class="service">
                <div class="rounded-lg overflow-hidden shadow-md mb-10">
                    <div class="bg-cyan-light h-20 flex justify-center items-center">
                        <h2 class="text-center text-base lg:text-lg font-medium py-5 mx-5">{{value.title}}</h2>
                    </div>
                    <div class="h-48 grid-cus pl-5 pr-10 pb-2 pt-5 relative">
                        <div v-for="(item, index) in value.list" :key="index" class="flex  z-50">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-circle-fill text-cyan mr-2 mt-2 flex-shrink-0" viewBox="0 0 16 16">
                                <circle cx="8" cy="8" r="8"/>
                            </svg>
                            <span class="mr-3">{{item}}</span>
                        </div>
                        <div class="topright"></div>                   
                    </div>                    
                </div>    
            </div>
            <template slot="prevButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-arrow-left-circle button-cus" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                </svg>
            </template>
            <template slot="nextButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                </svg>
            </template>
        </vue-agile>         
      </div>
  </section>
</template>

<script>
import home from '@/data/home.js'
import {VueAgile} from "vue-agile"
export default {
    components: {
        VueAgile
    },
    data() {
        return {
            data: home.product,
            options: {
            slidesToShow: 1,
            infinite: true,
            centerMode: false,
            navButtons: true,
            responsive: [
                    {
                        breakpoint: 640,
                        settings: {
                        slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                        slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 1280,
                        settings: {
                        slidesToShow: 3,
                        },
                    },
                ],
             },
        }
    }
}
</script>

<style lang="scss" scoped>
    .grid-cus {
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        grid-auto-flow: column;
    }
    .topright {
      width: 0;
      height: 0;
      border-top: 80px solid #36DFD9;
      border-left: 80px solid transparent;
      position: absolute;
      right: 0;
    }
          
</style>

<style lang="scss">

    .vue-home-treat {
        margin: 0 -20px;
        width: calc(100% + 40px);
        .service {
            padding: 0 18px;
        }
    }
    .vue-home-treat .agile__nav-button {
    position: absolute;
    // top: -40px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    bottom:35%;
    }
    .vue-home-treat .agile__nav-button--prev {
    left: -52px;
    }
    .vue-home-treat .agile__nav-button--next {
    right: -52px;
    }
    @media only screen and (max-width: 1023px) {
        .vue-home-treat .agile__nav-button {
        position: absolute;
        top: -40px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        bottom:auto;
        }
        .vue-home-treat .agile__nav-button--prev {
        left: 20px;
        }
        .vue-home-treat .agile__nav-button--next {
        right: 20px;
        }
    }

</style>
