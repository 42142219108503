import text from '@/data/text.js'
const services = {
   name: {
    title: 'Services',
    link: '/services'
   },
   banner: {
      name: () => services.name.title,
      title: 'We pride ourselves in providing solution to your problems',
      content: `${text.pt} is a value added chemical supplier`,
      bg: `url(${require("@/assets/services/top-banner.webp")})`
   },
   services: {
      name: () => services.name.title,
      title: `${text.pt} is a value added chemical supplier`,
      content: 'We pride ourselves in providing solution to your problems',
      list: [
         'We combine our high qualified products with sound technical expertise to provide you with the complete package',
         'Our office is equipped with an analysis laboratory for supporting analysis to ensure the plating solution is well maintained',
         'The quality control for our own range of product is also done in the laboratory',
         'It is our company\'s mission to share the knowledge of new technologies with our customers',
         'We provide technical training to educate our staff and our clients as well',
         'We share our trial reports with our clients to further develop their knowledge'
      ],
      image: `${require("@/assets/services/services.webp")}`,
      icon: `${require("@/assets/services/check.svg")}`,
   },
   client: {
      title: "Our Clients",
      list: [
         `${require("@/assets/services/client/client1.webp")}`,
         `${require("@/assets/services/client/client2.png")}`,
         `${require("@/assets/services/client/client3.webp")}`,
         `${require("@/assets/services/client/client4.webp")}`,
         `${require("@/assets/services/client/client5.webp")}`,
         `${require("@/assets/services/client/client6.webp")}`,
         `${require("@/assets/services/client/client7.webp")}`,
         `${require("@/assets/services/client/client8.webp")}`,
         `${require("@/assets/services/client/client9.webp")}`,
         `${require("@/assets/services/client/client10.webp")}`,
         `${require("@/assets/services/client/client11.webp")}`,
         `${require("@/assets/services/client/client12.webp")}`,
         // `${require("@/assets/services/client/client13.webp")}`,
         `${require("@/assets/services/client/client14.webp")}`,
         `${require("@/assets/services/client/client15.webp")}`,
         `${require("@/assets/services/client/client16.webp")}`,
         `${require("@/assets/services/client/client17.webp")}`,
         `${require("@/assets/services/client/client18.webp")}`,
         `${require("@/assets/services/client/client19.png")}`,
         // `${require("@/assets/services/client/client20.png")}`,
      ]
   }
}

export default services;