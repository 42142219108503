<template>
    <div class="relative">
        <section class="container mx-auto px-5">
            <!-- DESKTOP HEADER -->
            <div class="hidden lg:flex justify-between items-center">
                <div>
                    <router-link to="/">
                        <img src="@/assets/all/logo.svg" alt="" class="h-16">
                    </router-link>
                </div>
                <div>
                    <ul class="flex text-black space-x-5">
                        <li v-for="(value, idx) in data.links" :key="idx" class="capitalize py-8 menu-active"><router-link :to="value.link" class="px-2">{{value.title}}</router-link></li>
                    </ul>
                </div>          
            </div>

            <!-- MOBILE HEADER -->
            <div class="lg:hidden flex justify-between items-center py-3">
                <div>
                    <img src="@/assets/all/logo.svg" alt="" class="h-14">
                </div>
                <div class="cursor-pointer" @click="clickMenu">
                    <img v-if="!showMenu" src="@/assets/all/expand.svg" alt="">
                    <img v-else src="@/assets/all/close.svg" alt="">
                </div>
            </div>
            <div v-if="showMenu" class="absolute mobile-menu bg-white w-full left-0 right-0 mt-5 h-screen text-center">
                <ul class="mt-16">
                    <li v-for="(value, idx) in data.links" :key="idx" class="mobile-active" >
                        <router-link :to="value.link" class="flex justify-center"><span @click="clickMenu" class="py-2">{{value.title}}</span></router-link>
                    </li>
                </ul>
            </div>
        </section>        
    </div>
</template>

<script>
import footer from '@/data/footer.js'
export default {   
    data() {
        return {
            data: footer,
            showMenu: false,
        }
    },
    methods: {
    clickMenu() {
        this.showMenu = !this.showMenu;
        document.body.style.overflow = this.showMenu ? "hidden" : "visible";
    }
  },

}
</script>

<style lang="scss" scoped>
    .menu-active a {
        padding-top:1.65rem;
        padding-bottom:1.65rem;
    }
    .menu-active a.router-link-exact-active {
        border-bottom: 8px solid #33D1CB;
        padding-top: 1.65rem;
        padding-bottom: 1.65rem;
    }
    .mobile-menu {
        z-index: 100;
    }
    .mobile-active a.router-link-exact-active {
        background-color: #33D1CB;
        color: white;
    }
    .mobile-active a.router-link-exact-active > span {
        color: white;
    }
</style>