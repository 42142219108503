<template>
  <section>
      <vue-agile class="the-vue-agile" :navButtons="false" :dots="true">

          <!-- Slider 1 -->
          <div :style="data.s1.css" class="main-slide bg-cover">
              <div class="container mx-auto px-10 flex flex-col items-center justify-center h-full">
                  <h2 class="text-3xl lg:text-5xl font-semibold text-center mb-5">{{data.s1.title1}}</h2>
                  <div class="bg-cyan w-52 h-1 mb-8"></div>
                  <div class="text-xl font-semibold">{{data.s1.title2}}</div>
                  <div>
                      <div v-for="(value, idx) in data.s1.list" :key="idx" class="flex my-4">
                          <img src="@/assets/home/slider/check.svg" alt="" class="flex-shrink-0 w-7 mr-4">
                          <p class="font-semibold">{{value}}</p>
                      </div>
                  </div>
              </div>
          </div>

        <!-- Slider 2 -->
          <div :style="data.s2.css" class="main-slide bg-cover bg-right">
              <div class="container mx-auto px-10 flex flex-col justify-center h-full">
                  <div class="lg:w-3/5 self-end flex flex-col items-center lg:items-start text-center lg:text-left">
                    <h2 class="text-2xl lg:text-4xl font-semibold mb-3">{{data.s2.title1}}</h2>
                    <div class="bg-cyan w-52 h-1 mb-3"></div>                  
                    <h3 class="text-xl lg:text-2xl tracking-wide mb-12">{{data.s2.title2}}</h3>
                    <h1 class="text-4xl leading-snug lg:text-5xl lg:leading-snug font-bold">{{data.s2.content}}</h1>
                  </div>
              </div>
          </div>

            <!-- Slider 3 -->
          <div :style="data.s3.css" class="main-slide bg-cover bg-left">
              <div class="container mx-auto px-10 flex flex-col justify-center h-full">
                  <div class="lg:w-3/5 self-end flex flex-col items-center lg:items-start text-center lg:text-left">
                    <h2 class="text-2xl lg:text-4xl font-semibold mb-3">{{data.s3.title1}}</h2>
                    <div class="bg-cyan w-52 h-1 mb-3"></div>                  
                    <h3 class="text-xl lg:text-2xl tracking-wide mb-12">{{data.s3.title2}}</h3>
                    <h1 class="text-4xl leading-snug lg:text-5xl lg:leading-snug font-bold">{{data.s3.content}}</h1>
                  </div>
              </div>              
          </div>

          <!-- Slider 4 -->
          <div :style="data.s4.css" class="main-slide bg-cover bg-left">
              <div class="container mx-auto px-10 flex flex-col justify-center h-full">
                  <div class="lg:w-3/5 self-center flex flex-col items-center lg:items-start text-center lg:text-left">
                    <h2 class="text-2xl lg:text-4xl font-semibold mb-3">{{data.s4.title1}}</h2>
                    <div class="bg-cyan w-52 h-1 mb-3"></div>                  
                    <h3 class="text-xl lg:text-2xl tracking-wide mb-12">{{data.s4.title2}}</h3>
                    <h1 class="text-3xl leading-snug lg:text-4xl lg:leading-snug font-bold">{{data.s4.content}}</h1>
                  </div>
              </div>              
          </div>

          <!-- Slider 5 -->
          <div :style="data.s5.css" class="main-slide bg-cover bg-center">
              <div class="container mx-auto px-10 flex flex-col justify-center h-full">
                  <div class="lg:w-3/5 self-start flex flex-col items-center lg:items-start text-center lg:text-left">
                    <h2 class="text-2xl lg:text-4xl font-semibold mb-3">{{data.s5.title1}}</h2>
                    <div class="bg-cyan w-52 h-1 mb-3"></div>                  
                    <h3 class="text-xl lg:text-2xl tracking-wide mb-12">{{data.s5.title2}}</h3>
                    <h1 class="text-4xl leading-snug lg:text-5xl lg:leading-snug font-bold">{{data.s5.content}}</h1>
                  </div>
              </div>              
          </div>


      </vue-agile>
  </section>
</template>

<script>
import home from '@/data/home.js'
import { VueAgile } from "vue-agile";
export default {
    components: {
        VueAgile
    },
    data() {
        return {
            data: home.slider
        }
    }
}
</script>

<style lang="scss" scoped>
 .main-slide {
     height: 600px;
    //  .wrap-slide {
    //     grid-template-columns:  1fr;
    //     @media only screen and (min-width: 1024px) {
    //         grid-template-columns: 6fr 5fr;
    //     }
    //     @media only screen and (min-width: 1280px) {
    //         grid-template-columns: 3.5 5fr;
    //     }   
    //  }
 }
   h1, h2, h3, h4, h5, h6, span, div, p {
  color: white;   
   }
</style>


<style lang="scss">
.the-vue-agile {
  position: relative;
}
// .agile__nav-button {
//   background: transparent;
//   border: none;
//   color: #fff;
//   cursor: pointer;
//   font-size: 32px;
//   -webkit-transition-duration: 0.3s;
//   transition-duration: 0.3s;
// }
// .the-vue-agile .agile__nav-button {
//   position: absolute;
//   bottom: 0%;
//   @media only screen and (min-width: 640px) {
//            bottom:50%;
//     }   
//   -webkit-transform: translateY(-50%);
//   transform: translateY(-50%);
// }


.the-vue-agile  .agile__actions {
    justify-content: start !important;
    align-items: flex-start;
    position: absolute;
    bottom: 30px;
    display: inline-block;
    left: 6%;   
    @media only screen and (min-width: 500px) {
    left: 7%;   
    }
    @media only screen and (min-width: 600px) {
    left: 8%;   
    }
    @media only screen and (min-width: 800px) {
    left: 9%;   
    }
    @media only screen and (min-width: 1000px) {
    left: 10%;   
    }
     @media only screen and (min-width: 1000px) {
    left: 11%;   
    }
    @media only screen and (min-width: 1200px) {
    left: 12%;   
    }
    @media only screen and (min-width: 1400px) {
    left: 13%;   
    }
      @media only screen and (min-width: 1600px) {
    left: 15%;   
    }
    @media only screen and (min-width: 1800px) {
    left: 21%;   
    }
    // @media only screen and (min-width: 1800px) {
    // left: 55%;  
    // }          
             
  }

.the-vue-agile  .agile__dot {
    margin: 0 6px;
  }
.the-vue-agile  .agile__dot button {
    background-color: rgba(255, 255, 255, 0.445);
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 14px;
    font-size: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    width: 14px;
  }

.the-vue-agile  .agile__dot--current button,
  .the-vue-agile .agile__dot:hover button {
    background-color: rgba(255, 255, 255, 0.952);
  }

</style>