const product = {
    name: {
        title: "Products & Application",
        link: "/product-application"
    },
    banner: {
        name: () => product.name.title,
        title: 'We set ourselves to provide in-depth technical expertise',
        content: `PT. Surya Langgeng Indonesia is a value added chemical supplier`,
        bg: `url(${require("@/assets/products/top-banner.webp")})`
     },
     product1: {
            headline: {
                title: 'Cleaner & Stripper',
                css: {
                   backgroundImage: `url(${require("@/assets/products/cleaner.webp")})`
                }
            },
            p1: {
                title: 'Cleaner',
                content: 'We have a wide and variety of Cleaners, High Efficiency, Phospate Free, Emulsifying Type, Simple waste water Treatment for any kind of Substrates (Steel, Alumunium, Zinc Diecasting, Plastic). Our cleaners can be used both in barrel or rack process. Ultrasonic cleaner also available and can be used easily. Our cleaners has been qualified for heavy duty industrial oil and an electrocleaner process will also completing the whole pretreatment process to get the very clean and active surface prior to electroplated or other finishing process',
            },
            p2: {
                title: 'Stripper',
                content: 'We have several important Stripper product for strip off Nickel, Chrome, Tin or jig from metal deposit. Simple and fast process with easy handle Waste water treatment process is the key points for our Stripper product',
            },
            products: {
                title: 'Products',
                list: [
                    'Enprep 101', 'Enprep 142', 'Enprep 220 EC', 'Enprep 135', 'Enprep Flex', 'Elpelyt Ni 999', 'Actane 345'
                ]
            },
            status: false,
            linkId: 'cleaner-stripper'
        },
    product2: {
            headline: {
                title: 'Hardchrome',
                css: {
                   backgroundImage: `url(${require("@/assets/products/hardchrome.webp")})`
                }
            },
            p1: {
                title: 'ANKOR',
                content: `<p><b>ANKOR</b> hard chrome coatings are produced and used by industrial applicators to improve wear and tear, reduce friction or enhance corrosion protection on various articles.</p>
                </br>
                <p>Our application-specific customized plating products and additives in combination with our global, technical expertise enable ANKOR applicators to produce superior hard chrome coatings with the lowest cost per part on basically every metallic substrate.</p>
                </br>
                <p>Our individual solutions for the Automotive Industry, as well as Hydraulics and Fluid Power, Oil & Gas and Job Shops add significant value to the plating shop as well as to the OEM.</p>
                </br>
                <p>Good Practice Sheets for Uses of Chromium Trioxide.</p>`,
                image: `${require("@/assets/products/ankor.webp")}`,
            },
            p2: {
                title: 'Hydraulic Rod',
                content: `<p><b>ANKOR</b> Hard chrome processes for hydraulic bars and cylinders meet the demand of this market that is driven by low cost and high corrosion resistance. The ANKOR coatings are high in micro-cracks and free of nodules in as-plated condition.</p>
                </br>    
                <p>Pre-plated 6m Hydraulics bars are typically plated in conventional rack lines or in more advanced and compact horizontal continuous plating lines with one or multiple tanks.</p>
                </br>
                <p>Hydraulic Cylinder is typically plated with single or multiple Hard Chromium coatings in vertical hoist lines or horizontally when the length and diameter is bigger. Our processes are suitable for all types of equipment.</p>`,
                image: `${require("@/assets/products/hydraulic.webp")}`,
            },
            p3: {
                title: 'Oil & Gas',
                list: [
                    'Single-Lobe Pump Rotors',
                    'Multi-Lobe Pump Rotors',
                    'Ball Valves'
                ]
            },
            p4: {
                title: 'Shock Absorber Piston Rods',
                content: `<p>ANKOR Hard Chrome Processes are OEM approved and meet the specification of the major <b>Shock Absorber OEMs and Tier 1s</b>, in this safety relevant application. The piston or hollow rod of automotive shock absorbers is plated with an average of 15 µm to 30µm micro – cracked Hard chrome for wear- and corrosion protection in combination with their low friction requirements.</p>
                </br>
                <p>ANKOR chemical solutions meet the present and future demand of the industry for high yields, lowest cost per part, long term planning security and ELV compliance. ANKOR technology for shock absorbers can be installed in Zero discharge plating equipment.</p>`
            },
            p5: {
                title: 'Engine Valves',
                content: `<p>ANKOR Hard Chrome Processes are OEM approved and meet the specification of the major <b>Engine Valve OEMs and Tier 1s</b>, in this automotive application.</p>
                </br>
                <p>The valve shaft is plated in average with 5 - 8 µm micro – cracked Hard Chromium for wear- and corrosion protection as well as low friction requirements.</p>
                </br>
                <p>ANKOR chemical solutions meet the present and future demand of the Industry for high yields, lowest cost per part and long term planning security and compliance</p>`
            },
            p6: {
                title: 'Products',
                list: [
                    'Enprep 220 EC',
                    'Ankor 1127 SR',
                    'Ankor 1127 SR Compound',
                    'Ankor 1127 Make up Solution',
                    'Chrome Stripper EL 50',
                    'Ankor Dyne MS30'
                ]
            },
            status: false,
            linkId: 'hardchrome'
        },
     product3: {
        headline: {
            title: 'Decorative System',
            css: {
               backgroundImage: `url(${require("@/assets/products/decorative.webp")})`
            }
        },
        p1: {
            title: 'Decorative System',
            content: `<p>Nothing captures today's style and fashion like chromium plating. Elegant, timeless, enduring, and truly iconic. Our decorative finishes range offers a large styling choice and engineering compliance like no others.</p>
                </br>
            <p>Our trivalent chromium portfolio in combination with satin nickel offering stunning combinations of bright to satin and white to dark finishes. Not only do they deliver a wide range pallet of colors and finishes, but they also meet today's most demanding performance standards e.g. corrosion resistance.</p>`,
            list: ['Plating on Plastic',
                'Plating on Zinc Diecast Base',
                'Cylinder Printing',
                'Precious Metal',
                'Accessories',
                'Aluminium',
                'Brass & Copper',
                'Ferrous']
        },
        p2: {
            title: 'Products',
            list: [
                'Elpelyt Carrier x5',
                'Elpelyt GS6',
                'Elpelyt IS Carrier',
                'Elpelyt IS Brightener',
                'Wetting agent 62A',
                'Enstrip NP',
                'Elpelyt Ni 999',
            ]
        },
         status: false,
         linkId: 'decorative-system'
     },
     product4: {
        headline: {
            title: 'Anti Corrosion',
            css: {
               backgroundImage: `url(${require("@/assets/products/anti-corrosion.webp")})`
            },
        },
        p1: {
            title: 'Anti Corrosion',
            content: `<p>As the worldwide leader in Anti-Corrosion Coatings, We understands exactly what it takes to satisfy our customers' needs: the ability to listen to their challenges and the expertise to provide them with the highest performing, customized solutions possible.</p>
            </br>
            <p>From the smallest screws that hold together the electronics in a PDA to the massive tension rods on deep sea oil platforms, we take the time and effort to understand the specific requirements of each project and then deliver the industry’s most functional and protective coating ever conceived.</p>
            </br>
            <p>Our portfolio comprises electroplated zinc and zinc alloy systems, mechanically plated zinc, trivalent passivates, topcoats and friction control. Our systems are specified by many of the world’s largest automotive, electronics, construction and aerospace applicators.</p>
            </br>
            <p>As environmental issues continue to be a catalyst for change, we are consistently introducing new or redesigned solutions that completely avoid or minimize pollution risks, most notably within our hexavalent chrome-free ZinKlad systems package.</p>`,
            image:   `${require("@/assets/products/anti-corrosion-2.webp")}`,
        },
        p2: {
            title: 'Products',
            list: [
                'Zinc & Zinc Alloy System',
                'Passivation',
                'Top Coat',
                'Friction Control'
            ]
        },
        status: false,
        linkId: 'anti-corrosion'
     },
     product5: {
         headline: {
            title: 'Electroless Nickel',
            css: {
               backgroundImage: `url(${require("@/assets/products/electroless.webp")})`
            },
         },
         p1: {
             title: 'Electroless Nickel',
             content: `<p>From innovation in the lab to innovation at the process facility, it's not just the chemistry with our electroless plating technology that solves your problems.</p>
             </br>   
             <p>From the simplest shape to even the most complex configurations, the versatility of our coatings have demonstrated their exceptional capabilities to meet challenging application demands.</p>
             </br>
             <p>Our Enplate, NiKlad and NiKlad ELV products meet many specifications for electroless plating.</p>
             </br>
             <p>From Low Phosporous to High Phosporous Content is available to meet your demand and requirement</p>`,
             image: `${require("@/assets/products/electroless-nickel.webp")}`,
             list: [
                'Automotive',
                'Mining',
                'Military Coating',
                'Mold & Dies',
                'Printing Textile',
                'Wood & Paper',
             ]
         },
         p2: {
             title: 'Products',
             list: ['Enplate 806','ENStrip Enviroprotect']
         },
         status: false,
         linkId: 'electroless-nickel'
     },
    product6: {
        headline: {
            title: 'Electroplating Basic Chemicals',
            css: {
               backgroundImage: `url(${require("@/assets/products/electroplating-basic.webp")})`
            },
         },
         p1: {
             title: 'Products',
             list: [
                 {
                     title: 'Nickel',
                     list: [
                        'Nickel Square 1x1 inch Sumitomo',
                        'Nickel Sulfat Sumitomo',
                        'Nickel Chloride Sumitomo'
                     ]
                 },
                 {
                     title: 'Copper',
                     list: [
                        'Copper Sulphate ex Mitsubishi',
                        'Copper Anode'
                     ]
                 },
                 {
                     title: 'Chrome',
                     list: [
                        'Chromic Acid ACCP',
                        'Chrome Anode (Pb/Sn Alloy)',
                        'Barium Chloride'
                     ]
                 },
                 {
                     title: 'And More',
                     list: [
                        'Sulfuric Acid Hoch Lab Grade',
                        'Caustic Soda MicroPearl',
                        'Sodium Metabisulphite',
                        'Nitric Acid',
                        'And More'
                     ]
                 }
             ]
         },
         status: false,
         linkId: 'electroplating-basic-chemicals'
    },
    product7: {
        headline: {
            title: 'Electroplating Chemical Laboratory Reagents',
            css: {
               backgroundImage: `url(${require("@/assets/products/electroplating-chemical.webp")})`
            },
         },
         p1: {
             title: 'Products',
             list: [
                'Tiosulfat 0,1N',
                'Sulfuric Acid 30%',
                'Kalium Iodide 10%',
                'Indicator Starch',
                'Sodium Hydroxide 10%',
                'EDTA 0,1 M',
                'Hydrocloric Acid',
                'Barium Chloride',
                'Silver Nitrat',
                'Etc'
             ]
         },
         status: false,
         linkId: 'electroplating-chemical-laboratory-reagents'
    },
    product8: {
        headline: {
            title: 'Electroplating Equipments',
            css: {
               backgroundImage: `url(${require("@/assets/products/electroplating-equipments.webp")})`
            },
         },
         p1: {
             title: 'Electroplating Equipments',
             list: [
                'Sulfameter Centrifuge',
                'Hot Plate & Stirrer',
                'Hullcell Tank',
                'Anode Hullcell',
                'Baume Hydrometer 0-70',
                'TDS Meter (portable)',
                'Glass Apparatus',
                'Measuring Apparatus',
                'Etc.'
             ],
             images: [
                `${require("@/assets/products/equipments/1.webp")}`,
                `${require("@/assets/products/equipments/2.webp")}`,
                `${require("@/assets/products/equipments/3.webp")}`,
                `${require("@/assets/products/equipments/4.webp")}`,
                `${require("@/assets/products/equipments/5.webp")}`,
                `${require("@/assets/products/equipments/6.webp")}`,
                `${require("@/assets/products/equipments/7.webp")}`,
                `${require("@/assets/products/equipments/8.webp")}`,
                `${require("@/assets/products/equipments/9.webp")}`,
                `${require("@/assets/products/equipments/10.webp")}`,
                `${require("@/assets/products/equipments/11.webp")}`,
                `${require("@/assets/products/equipments/12.webp")}`,                   
             ]
         },
         status: false,
         linkId: 'electroplating-equipments'
    },
    ourProduct: {
        headline: {
            title: 'Our Product ',
            css: {
               backgroundImage: `url(${require("@/assets/products/our-product.webp")})`
            },
         },
         p1: {
             title: 'Product List',
             list: [
                `<b>I.</b>  Basic Chemicals (Nickel Anode & Salt, Copper Anode & Salt, etc)`,
                `<b>II.</b> Pretreatment/Cleaner`,
                `<b>III.</b> Nickel Plating (Decorative Base Material)`,
                `<b>IV.</b> Acid Copper Plating (Decorative)`,
                `<b>V.</b>  Hard Copper Plating (Rotogravure)`,
                `<b>VI.</b> Chrome Plating (Decorative) `,
                `<b>VII.</b> Hard Chrome Plating (Functional Chrome)`,
               `<b>VIII.</b> Cyanide Free Alkaline Zinc Plating (Anti Corrosion)`,
                `<b>IX.</b> Acid Zinc Plating (Anti Corrosion)`,
                `And other processes.`,
             ]
         },
         p2: {
             title: 'Our strength with wide range of series :',
             list: [
                'ENPREP SERIES',
                'UDIQUE SERIES',
                'ELPELYT SERIES',
                'ANKOR SERIES',
                'TRIPASS SERIES',
                'NIKLAD SERIES',
             ]
         },
         linkId: 'our-product'
    }        
}

export default product