<template>
    <section class="container mx-auto px-5">
        <div class="grid grid-cus gap-12">
            <div>
                <img :src="data.image1" alt="" class="w-3/4 mx-auto mb-5">
                <!-- <img :src="data.image2" alt=""> -->
            </div>
            <div>
                <h3 class="uppercase text-xl md:text-2xl font-medium tracking-wider">{{data.name()}}</h3>
                <div class="bg-cyan h-1 w-20 mt-2 mb-5"></div>
                <h1 class="text-xl md:text-3xl font-semibold mb-6">{{data.title}}</h1>
                <p class="text-xl mb-5">{{data.content}}</p>
                <div>
                    <div v-for="(item, index) in data.list" :key="index" class="flex mb-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-circle-fill text-cyan flex-shrink-0 mr-4 mt-2" viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8"/>
                        </svg>
                        <p class="text-lg">{{item}}</p>
                    </div>
                </div>
                <div class="flex justify-center mt-10">
                    <router-link to="/contact">
                        <span class="border-cyan border-2 px-7 py-2 rounded-full inline-block text-center text-base">
                            {{data.contact}}
                        </span>
                    </router-link>
                </div> 
            </div>
        </div>
    </section>        
</template>

<script>
import home from '@/data/home.js'
export default {
    data() {
        return {
            data: home.welcome
        }
    }
}
</script>

<style lang="scss" scoped>
    .grid-cus {
        grid-template-columns: 1fr;
        @media only screen and (min-width: 1024px) {
            grid-template-columns: 1fr 2fr; 
        }
    }
</style>