<template>
    <section class="container mx-auto px-5">
        <div class="grid grid-cus gap-12">
            <div>
                <!-- <img :src="data.image1" alt="" class="w-full mt-5"> -->
                
            </div>
            <div>
                <h3 class="uppercase text-xl md:text-2xl font-medium tracking-wider">{{data.name}}</h3>
                <div class="bg-cyan h-1 w-20 mt-2 mb-5"></div>
                <h1 class="text-xl md:text-3xl font-semibold mb-6">{{data.title}}</h1>
                <p class="text-xl mb-5">{{data.content}}</p>
                <div>
                    <div v-for="(item, index) in data.list" :key="index" class="flex mb-5">
                        <img src="@/assets/home/service/check.svg" alt="" class="mr-5 self-start mt-1">
                        <p class="text-lg">{{item}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>        
</template>

<script>
import home from '@/data/home.js'
export default {
    data() {
        return {
            data: home.service
        }
    }
}
</script>

<style lang="scss" scoped>
    .grid-cus {
        grid-template-columns: 1fr;
        @media only screen and (min-width: 1024px) {
            grid-template-columns: 1fr 2fr; 
        }
    }
</style>