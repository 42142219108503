import text from '@/data/text.js'
const home = {
    name: {
        title: "Home",
        link: "/"
    },
    slider: {
        s1: {
            title1: 'Metal Finishing Solution Specialist',
            title2: 'AUTHORIZED DISTRIBUTOR :',
            list: [
                'MACDERMID ENTHONE',
                'CHROMIC ACID ACCP',
                'MICROPIONEER XRAY',
                'DENORA',
                'AND MORE'
            ],
            css: {
                backgroundImage: `url(${require("@/assets/home/slider/slider1.webp")})`
            }
        },
        s2: {
          title1: 'Metal Finishing Solution Specialist',
          title2: 'for the Surface Coating Industry',
          content: 'Automotive Decorative Systems',
          css: {
            backgroundImage: `url(${require("@/assets/home/slider/slider2.webp")})`
        }
        },
        s3: {
           title1: 'Metal Finishing Solution Specialist',
           title2: 'for the Surface Coating Industry',
           content: 'Building Hardware',
           css: {
            backgroundImage: `url(${require("@/assets/home/slider/slider3.webp")})`
        }
        },
        s4: {
            title1: 'Metal Finishing Solution Specialist',
            title2: 'for the Surface Coating Industry',
            content: 'HARDCHROME ELECTROLESS NICKEL Heavy Equipment, Energy & Chemicals',
            css: {
                backgroundImage: `url(${require("@/assets/home/slider/slider4.webp")})`
            }
        },
        s5: {
            title1: 'Metal Finishing Solution Specialist',
            title2: 'for the Surface Coating Industry',
            content: 'Automotive Functional Systems',
            css: {
                backgroundImage: `url(${require("@/assets/home/slider/slider5.webp")})`
            }
        }
    },
    welcome: {
        image1: `${require("@/assets/home/welcome/logo.webp")}`,
        image2: `${require("@/assets/home/welcome/team.webp")}`,
        name: () => home.name.title,
        title: `Welcome to ${text.pt}`,
        content: `${text.pt} currently specialises in supplying chemicals for electroplating industry. We do more than just selling chemicals`,
        list: [
            'We provide the complete package, quality of product together with the supporting of laboratory analysis and technical support',
            'We are the official distributor for Macdermid Enthone, manufacturer of high performance specialty chemical for metal finishing industry',
            'We also supplies the basic chemicals for electroplating',
            'We are able to optimize your production through the quality and benefit of our specialty chemical, basic chemical and our technical expertise obtained from many field experiences'
        ],
        contact: text.contact
    },
    product: {
        name: 'Products & Application',
        title: `Product and Application ${text.pt}`,
        list: [
            {
                title: 'CLEANER & STRIPPER',
                list: [
                    'Enprep 101',
                    'Enprep 220 EC',
                    'Enprep Flex',
                    'Actane 345',
                    'Etc.'
                ],
            },
            {
                title: 'HARDCHROME',
                list: [
                    'Engine Valves',
                    'Hydraulic Rod',
                    'Oil & Gas',
                    'Shock Absorber Piston Rods'
                ]
            },
            {
                title: 'DECORATIVE SYSTEM',
                list: [
                    'Plating On Plastic',
                    'Plating On Zinc Diecast Base',
                    'Cylinder Printing',
                    'Precious Metal',
                    'Etc.'
                ]
            },
            {
                title: 'ANTI CORROSION',
                list: [
                    'Passivation',
                    'Top Coat',
                    'Friction Control',
                    'Zinc & Zinc Alloy System'
                ]
            },
            {
                title: 'ELECTROLESS NICKEL',
                list: [
                    'Automotive',
                    'Mold & Dies',
                    'Printing Textile',
                    'Wood & Paper',
                    'Mining',
                    'Military Coating',
                ]
            },
            {
                title: 'ELECTROPLATING BASIC CHEMICALS',
                list: [
                    'Nickel',
                    'Copper',
                    'Chrome',
                    'And More'
                ]
            },
            {
                title: 'ELECTROPLATING CHEMICAL LABORATORY REAGENTS',
                list: [
                    'Indicator Starch',
                    'Hydrocloric Acid',
                    'Barium Chloride',
                    'Silver Nitrat',
                    'Etc.',
                ]
            },
            {
                title: 'ELECTROPLATING EQUIPMENTS',
                list: [
                    'Sulfameter Centrifuge',
                    'Hot Plate & Stirrer',
                    'Hullcell Tank',
                    'Anode Hullcell',
                    'Etc.',
                ]
            }
        ]
    },
    service: {
        image1: `${require("@/assets/home/service/service.webp")}`,
        name: 'Services',
        title: `${text.pt} is a value added chemical supplier` ,
        content: `We pride ourselves in providing solution to your problems`,
        list: [
            'We combine our high qualified products with sound technical expertise to provide you with the complete package.',

            'Our office is equipped with an analysis laboratory for supporting analysis to ensure the plating solution is well maintained.',
            
            'The quality control for our own range of product is also done in the laboratory.',
            
            'It is our company\'s mission to share the knowledge of new technologies with our customers.',
            
            'We provide technical training to educate our staff and our clients as well.',
            
            'We share our trial reports with our clients to further develop their knowledge.'
        ],
        contact: text.contact
    },    
}
export default home