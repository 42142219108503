import text from '@/data/text.js'
const about = {
    name: {
        title: "About Us",
        link: "/about"
    },
    banner: {
        name: () => about.name.title,
        title: 'Become the Leading Chemical Supplier',
        content: `We believe in the importance and value of experience in our business`,
        bg: `url(${require("@/assets/about/top-banner.webp")})`
     },
     aboutUs: {
         name: () => about.name.title,
         pt: () => text.pt,
         content: `<p>Established in April 2014, PT Surya Langgeng Indonesia (SLI) sets out to immediately become the leading chemical supplier for the Metal finishing industry. Backed up with the complete support facilities (Laboratory, large warehouse, delivery fleet and burning commitment to serve), we set ouselves to provide in-depth technical expertise, quality Hi-tech chemistry and impeaccable service. Our company seeks to embody evolution by constantly improving our operations, from services to product lines, to always stay ahead of the rest.</p>
          </br>  
         <p>We believe in the importance and value of experience in our business. Our team features seasoned veterans in the field of electroplating joined with a youthful and enthusiastic cast that is seeking to continually grow. Together, we are ready to provide a customer centred service and be thego to supplier of specialty metal finishing solutions.</p>`,
         image: `${require("@/assets/about/logo.webp")}`
     },
     visiMisi: {
         name: 'Vision, Mission & Value',
         list:[
             {
                 title: "Our Vision",
                 content: "Our vision is to be a dynamic company that gives the total solutions to our customers through technology and excellent service"
             },
             {
                 title: 'Our Mission',
                 content: 'Our mission is build a lasting partnership that brings value to our customers and we want you to join it'
             },
             {
                 title: 'Our Value',
                 content: 'Customer satisfaction and Quality of service to be success together'
             }
         ]
     },
     founder: {
         name: "Founder",
         title: `${text.pt} is managed by an experienced professional in the electroplating circles`,
         content: 'He is one of the shareholders of the company and with 18 years of experiences in the field, is able to bringing added value to customers. He is joined with a youthful and enthusiastic team that is seeking to grow the company by providing a customer centred service. Surya Langgeng team is ready to be the go-to become the leading supplier of specialty metal finishing solutions',
         person: 'Iwan Susanto',
         sign: `${require("@/assets/about/sign.webp")}`
     }
}

export default about