<template>
  <div>
    <main-slide ></main-slide>
    <div class="flex justify-center relative">
      <img src="@/assets/home/blue/1.webp" alt="" class="absolute w-44 lg:-mt-20 -mt-5 z-10">
    </div>

    <div class="bg-grad py-32 relative">
          <welcome-to></welcome-to>
          <div class="text-center space-y-3 mt-32 pb-10">
            <div class="uppercase tracking-wider text-2xl font-medium">Suppliers from</div>
            <a class="text-2xl bg-gray-200 inline-block px-20 py-5 rounded-xl" href="https://www.spmco.co.kr/eng/main/main.html
" target="_blank">
              SHIN POONG METAL CO
            </a>
          </div>
          <img src="@/assets/home/blue/2.webp" alt="" class="absolute w-20 top-80">
          <img src="@/assets/home/blue/3.webp" alt="" class="absolute w-24 right-0 top-52 hidden lg:block">
          <list-products class="mt-20"></list-products>
    </div>

    <div class="bg-grad2 pt-20 relative">
          <service-home class="mb-20"></service-home>
          <img src="@/assets/home/blue/4.webp" alt="" class="absolute w-24 -top-20 lg:top-auto lg:-mt-60">
          <img src="@/assets/home/blue/5.webp" alt="" class="absolute w-24 -mt-80 right-0">
          <div class="line-cus container mx-auto"></div>
          <our-client-all></our-client-all>
    </div>
  </div>
</template>

<script>
import OurClientAll from '../components/all/OurClientAll.vue'
import ListProducts from '../components/home/ListProducts.vue'
import MainSlide from '../components/home/MainSlide.vue'
import ServiceHome from '../components/home/ServiceHome.vue'
import WelcomeTo from '../components/home/WelcomeTo.vue'

export default {
  components: {
    MainSlide,
    WelcomeTo,
    ListProducts,
    ServiceHome,
    OurClientAll
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s - PT. Surya Langgeng Indonesia',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .bg-grad {
        background: rgb(254,254,254);
        background: linear-gradient(0deg, rgba(254,254,254,1) 0%, rgba(234,238,243,1) 100%);        
  }
  .bg-grad2 {
    background: rgb(243,244,245);
    background: linear-gradient(0deg, rgba(243,244,245,1) 0%, rgba(230,234,238,1) 100%);
  }
  .line-cus {
    // background: rgb(255,255,255);
    background: rgb(234,238,243);
    background: linear-gradient(90deg, rgba(234,238,243,0) 0%, rgba(210,214,218,1) 25%, rgba(210,214,218,1) 75%, rgba(234,238,243,0) 100%);
      height: 2px;
}
</style>