<template>
  <section class="py-20">
      <div class="container mx-auto px-5">
        <h3 class="uppercase text-center text-2xl font-medium tracking-wider">{{data.title}}</h3>
        <div class="bg-cyan h-1 w-20 mt-2 mx-auto mb-12"></div>
        <div class="flex-custom">
            <div v-for="(value, idx) in data.list" :key="idx" class="item-img">
                <img :src="value" alt="" class="w-full h-20 object-contain">
            </div>
        </div>          
      </div>
  </section>
</template>

<script>

import services from '@/data/services.js'

export default {
    data() {
        return {
            data: services.client
        }
    }
}
</script>

<style lang="scss" scoped>
    .flex-custom {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .item-img {
            flex: 0 0 calc(50% - 10px);
            padding: 1.2rem;
        }
        @media only screen and (min-width: 375px) {
            .item-img {
                flex: 0 0 calc(50% - 10px);
                padding: 1.5rem;
            }
        }
        @media only screen and (min-width: 560px) {
            .item-img {
                flex: 0 0 calc(33% - 10px);
                padding: 1.5rem;
            }
        }
        @media only screen and (min-width: 1024px) {
            .item-img {
                flex: 0 0 calc(25% - 10px);
                padding: 2rem;
            }
        }        
    }

</style>