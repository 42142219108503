<template>
  <section class="bg-darkblue footer-main text-white relative">
      <div class="container mx-auto px-5 grid footer-cols gap-20 grid-cols-1 md:grid-cols-2 py-5">
          
          <!-- CONTACT -->
          <div>
            <div class="block md:inline-block mb-6 text-center md:text-left">
                <img src="@/assets/all/logo-only.svg" alt="" class="mx-auto mb-2">
                <h3 class="text-xl font-robcon ">{{data.pt}}</h3>
            </div>
            <div class="space-y-5 mb-5">
                <div class="flex items-start">
                    <img src="@/assets/all/telp.svg" alt="" class="mr-3">
                    <div>{{data.contact.telp}}</div>
                </div>
                <div class="flex items-start">
                    <img src="@/assets/all/mail.svg" alt="" class="mr-3">
                    <div>{{data.contact.mail}}</div>
                </div>
                <div class="flex items-start">
                    <img src="@/assets/all/location.svg" alt="" class="mr-3">
                    <div>{{data.contact.location}}</div>
                </div> 
                <div class="flex md:justify-start justify-center">
                    <div class="bg-white inline-flex px-3 py-2 rounded-sm">
                        <img src="@/assets/all/maps.webp" alt="" class="w-10 object-contain mr-3">
                        <div class="my-auto">
                            <h4 class="text-black text-sm">{{data.pt}}</h4>
                            <a href="https://goo.gl/maps/GCMunK2fKQ8sycJ98"><span class="text-green-600 text-xs">View Larger map</span></a>
                        </div>
                    </div>   
                </div>   
            </div>      
          </div>

          <!-- OFFICE HOURS -->
          <div class="md:mt-16 text-center md:text-left">
              <h2 class="text-cyan text-xl font-medium">Office Hours</h2>
              <div class="bg-cyan h-0.5 w-24 mt-2 mx-auto md:mx-px"></div>
              <div class="space-y-3 mt-5">
                  <p>{{data.hours.days}}</p>
                  <p>{{data.hours.time}}</p>
                </div>
                <div class="space-y-3 mt-5">
                    <h3>Official distribution of</h3>
                    <img src="@/assets/all/macdermid.webp" alt="" class="w-44 mx-auto md:mx-px">    
                </div>  
          </div>

          <!-- QUICK LINKS -->
          <div class="lg:mt-16 text-center md:text-left">
              <h2 class="text-cyan text-xl font-medium">Quick Links</h2>
              <div class="bg-cyan h-0.5 w-24 mt-2 mb-5 mx-auto md:mx-px"></div>
              <div class="flex flex-col space-y-3">
                  <router-link v-for="(value,idx) in data.links" :key="idx" :to="value.link" class="border-b border-white border-opacity-25 pb-1.5">{{value.title}}</router-link>
              </div>
          </div>


          <!-- QUICK LINKS -->
          <div class="lg:mt-16 text-center md:text-left">
              <h2 class="text-cyan text-xl font-medium ">Products & Application</h2>
              <div class="bg-cyan h-0.5 w-24 mt-2 mb-5 mx-auto md:mx-px"></div>
              <div class="flex flex-col space-y-3">
                 <div  v-for="(value,idx) in data.product" :key="idx" >
                      <div>
                          <router-link :to="value.link + '#' + value.linkId" class="border-b border-white border-opacity-25 pb-1.5">{{value.title}}</router-link>
                      </div>
                 </div>
              </div>  
          </div>


      </div>
      <div class="container mx-auto px-5 text-sm py-5 text-center md:text-left">
          &copy; 2021 PT. Surya Langgeng Indonesia. All Rights Reserved
      </div>
      <a href="#">
        <div class="absolute right-0 bottom-40 lg:bottom-20 transform rotate-90 flex">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-forward-fill text-white w-7 transform -rotate-180" viewBox="0 0 16 16" >
                    <path d="m9.77 12.11 4.012-2.953a.647.647 0 0 0 0-1.114L9.771 5.09a.644.644 0 0 0-.971.557V6.65H2v3.9h6.8v1.003c0 .505.545.808.97.557z"/>
                </svg>
            <span class="text-white ml-2">Back to top</span>
        </div>          
        </a>  
  </section>
</template>

<script>
import footer from '@/data/footer.js'
export default {
    data() {
        return {
            data: footer
        }
    },
    methods: {
        // NOT WORK
    //     footerGoto() {
    //         let section = this.$router.currentRoute.hash.replace("#", "");
    //         if(section) {
    //             this.goto2(section)
    //         }
    //         console.log(this.$router)
    //     },
    //     goto2(refName) {
    //       let getElement = this.$refs[refName];
    //     //   console.log(this.$refs)
    //       if(typeof getElement !== 'undefined' || getElement) {
    //         let getElementOffsetTop = getElement.offsetTop;
    //         window.scrollTo(0, getElementOffsetTop) 
    //       }
    //   }
    }

}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1024px) {
    .footer-cols {
        grid-template-columns: 4.3fr 2fr 2fr 2.8fr;
    }
}

// .footer-cols {
//     grid-template-columns: 4fr 2fr 3fr 3fr;
// }

h3, div, p {
  color: white;
}

</style>