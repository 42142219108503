import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './css/index.css'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// disable right click
window.addEventListener('contextmenu', function (e) { 
  // do something here... 
  e.preventDefault(); 
}, false);


// disable ctrl + c / select dll
function disableselect() {
  return false;
}

function reEnable() {
  return true;
}

/** TO DISABLE SCREEN CAPTURE **/
document.addEventListener('keyup', (e) => {
  if (e.key == 'PrintScreen') {
      navigator.clipboard.writeText('');
      alert('Screenshots disabled!');
  }
});

/** TO DISABLE PRINTS WHIT CTRL+P **/
document.addEventListener('keydown', (e) => {
  if (e.ctrlKey && e.key == 'p') {
      alert('This section is not allowed to print or export to PDF');
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
  }
});

/* TO DO: There are combinations that remain to be solved 
  --> Windows+Shift+S
*/

document.onselectstart = new Function("return false");

if (window.sidebar) {
  document.onmousedown = disableselect;
  document.onclick = reEnable;
}


 // disable F12 key
document.onkeydown = function (e) {
         
          if(e.keyCode == 123) {
            return false;
        }
}